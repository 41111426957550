@import "./scss/font-awesome-pro/scss/fontawesome";
@import "./scss/font-awesome-pro/scss/regular";
@import "./scss/font-awesome-pro/scss/solid";
@import "./scss/font-awesome-pro/scss/light";
@import "./scss/font-awesome-pro/scss/brands";
//@import "./scss/font-awesome-pro/scss/thin";

@import "./../lib/third-party/@pqina/pintura/pintura.css";
@import '~quill/dist/quill.snow.css';

$primary: #388e3c;
$secondary: #1b5e20;
$accent: #ffca28;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
}

.event-description {
  blockquote {
    @apply p-4 my-4 border-l-4 border-gray-300;
  }
  ol {
    @apply list-decimal list-inside;
  }
  ul {
    @apply list-disc list-inside;
  }
  .ql-indent-1 {
    @apply ml-4;
  }
  .ql-indent-2 {
    @apply ml-8;
  }
  .ql-indent-3 {
    @apply ml-12;
  }
  .ql-align-center {
    @apply text-center;
  }
  .ql-align-right {
    @apply text-right;
  }
  .ql-align-justify {
    @apply text-justify;
  }
  a {
    @apply text-blue-500 hover:text-blue-600;
  }
}

//block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6;
@layer components {
  .card {
    @apply shadow bg-white p-4 mb-4;
  }
  .input {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6;
    &.error{
      @apply ring-2 ring-red-500;
    }
  }
  button.input {
    @apply text-left px-3;
  }
  .radio {
    @apply h-4 w-4 border-gray-300 text-green-500 focus:ring-green-500;
  }
  .checkbox {
    @apply h-4 w-4 rounded border-gray-300 text-green-500 focus:ring-green-500
  }
  .label {
    @apply block text-sm font-medium leading-6 text-gray-900;
  }
  button.button,
  .submit {
    @apply hover:bg-gradient-to-r my-2 flex w-full justify-center rounded-md bg-green-500 px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600;
    i {
      @apply w-4 h-4 text-center bg-center relative ml-1 mt-1 -mr-1;
    }
    &:disabled {
      @apply bg-gray-100 text-gray-400 cursor-not-allowed;
    }
  }
  .close {
    @apply inline-flex w-full justify-center rounded-full bg-gray-100 px-3 py-2 text-sm text-gray-500 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300;
  }
  button.button-secondary,
  button.button-alternate {
    @apply my-2 flex w-full justify-center rounded-md bg-yellow-500 px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600
  }

  .call-to-action-primary,
  .call-to-action-secondary,
  .call-to-action-white {
    i {
      @apply mt-0.5 mx-0.5;
    }
  }
  .call-to-action-primary {
    &:hover{
      @apply bg-gradient-to-br from-green-500 to-green-600 hover:bg-gradient-to-r text-white;
    }
  }
  .call-to-action-secondary
  {
    &:hover{
      @apply bg-gradient-to-br text-white;
    }
  }


  .call-to-action-primary {
    @apply relative inline-flex bg-gradient-to-br from-green-500 to-green-600 hover:bg-gradient-to-r justify-center rounded-md bg-green-500 px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600;
  }
  .call-to-action-secondary {
    @apply relative inline-flex bg-gradient-to-br from-yellow-500 to-orange-400 hover:bg-gradient-to-r justify-center rounded-full px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200;
  }
  .call-to-action-white {
    @apply relative inline-flex justify-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm font-semibold leading-6 text-green-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200;
  }
  .back-btn {
    @apply float-left mr-3 hover:text-green-600 py-1.5 px-3 hover:bg-gray-100 rounded-lg;
  }
  .back-btn-alt {
    @apply float-left mr-3 text-white hover:text-green-600 py-1.5 px-3 hover:bg-gray-100 rounded-lg;
  }
  .preview-btn {
    @apply float-right ml-3 hover:text-green-600 py-1.5 px-3 hover:bg-gray-100 rounded-lg;
  }
  .success-box {
    @apply bg-green-50 border-l-4 border-green-400 p-4 mb-4;
    p {
      @apply text-sm text-green-500;
    }
  }
  .error-box {
    @apply bg-red-50 border-l-4 border-red-400 p-4 mb-4;
    p {
      @apply text-sm text-red-500;
    }
  }
  .alert {
    @apply flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1;
  }
  .warning-box {
    @apply bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4;
    p {
      @apply text-sm text-yellow-500;
    }
  }
  .table {
    @apply min-w-full divide-y divide-gray-200 overflow-hidden border border-gray-200 sm:rounded-lg;
    thead th {
      @apply px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
    tbody {
      tr:nth-child(even) {
        @apply bg-gray-50
      }
      //tr {
      //  @apply hover:bg-gray-50;
      //}
      td {
        @apply px-6 py-4;
      }
    }
    tfoot {
      @apply bg-gray-50;
      tr {
        @apply hover:bg-gray-50;
      }
      td {
        @apply px-6 py-4;
      }
    }
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: "Open Sans", "Helvetica Neue", sans-serif; }

.brand {
  font-weight: 900;
  font-family: "Noto Sans","Open Sans","Helvetica Neue",sans-serif;
  color: $primary;
  span {
    color: $secondary;
  }
}

h1 {
  font-weight: 900;
  font-family: "Noto Sans","Open Sans","Helvetica Neue",sans-serif;
  color: $primary;
  span {
    color: $secondary;
  }
}
.home-content {
  @apply p-4 max-w-7xl m-auto;
}
.blog-content,
.page-width {
  @apply mx-auto w-full max-w-7xl pt-4 px-4;
}
.inner-blog-content,
.blog-content {
  p {
    @apply py-3;
  }
}

.badge {
  @apply ml-1 inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 group-hover:bg-green-200;
}

.page-content {
  @apply mx-auto w-full max-w-7xl pt-4 px-4 sm:px-6 lg:px-8 mb-10;
  p {
    @apply py-2;
  }
  h1, h2, h3, h4 {
    @apply text-gray-800 font-bold;
    small {
      @apply text-[0.5em] font-light;
    }
  }
  h1 {
    @apply border-b border-gray-300 pb-2 mb-4;
  }
}
.lowestprice {
  @apply text-gray-900;
  .decimal {
    display:none;
    @apply text-gray-900;
  }
  .cents {
    @apply text-gray-900 z-0;
    font-size: 0.4em;
    position: relative;
    top: -1em;
  }
}
.page-width,
.page-content {
  p {
    @apply py-2;
  }
  h1, h2, h3, h4 {
    @apply text-gray-800 font-bold;
    small {
      @apply text-[0.5em] font-light;
    }
  }
  h1 {
    @apply border-b border-gray-300 pb-2 mb-4;
  }
}
input:disabled {
  @apply bg-gray-100 text-gray-500;
}
a {
  cursor: pointer;
  @apply text-green-500 hover:text-green-600;
}
.primary-color {
  color: $primary;  
}
.accent-color {
  color: $secondary;
}